import { Link } from 'react-router-dom'
import { colors } from '../../colors'
import { useState } from 'react'

const PerDaySavings = ({ licenseLength, savePercent }) => {
  if (licenseLength === 1) return

  return (
    <p
      style={{
        fontWeight: 200,
        color: colors.splashDark,
        fontSize: '0.75rem',
        color: '#515151',
        margin: '2px 0px',
      }}
    >
      <b style={{ color: colors.black }}>save {savePercent}%</b> compared to 1
      day license
    </p>
  )
}

export const TurboCard = ({
  handleGetLicensePricing,
  handleGetLicenseLink,
  pricingConfig,
}) => {
  const [licenseLength, _licenseLength] = useState(30)

  const displayPrice = handleGetLicensePricing(
    pricingConfig,
    'turbo',
    licenseLength,
  )

  const one_day_cost = handleGetLicensePricing(pricingConfig, 'turbo', 1)
  const savePercent = (
    ((one_day_cost * licenseLength - displayPrice) /
      (one_day_cost * licenseLength)) *
    100
  ).toFixed(0)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        padding: '0.5rem 0.5rem',
        backgroundColor: '#FBFAF8',
        borderRadius: '15px 15px 15px 15px',
        marginTop: '2rem',
      }}
    >
      <h2
        style={{
          background: 'linear-gradient(135deg, #8b18ff, #2a5fff)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 800,
          paddingTop: '0.3rem',
          fontSize: '2.2rem',
        }}
      >
        <b style={{ fontSize: '2.5rem' }}>⚡</b> Turbo{' '}
        <b style={{ fontSize: '2.5rem' }}>⚡</b>
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(135deg, #8b18ff, #2a5fff) padding-box',
          padding: '4px',
          marginTop: '0.5rem',
        }}
      >
        <p style={{ color: '#FBFAF8', fontSize: '0.7em' }}>MOST POWERFUL</p>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: '4px',
          marginTop: '1rem',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🤑</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Auto accepts batches
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>⚡</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Fastest performance
        </p>

        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔍</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Runs in background
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔔</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Batch acceptance alerts
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: '14px',
            color: '#515151',
            textAlign: 'left',
            margin: '0.5rem 0rem',
            padding: '0 1rem',
          }}
        >
          <b>Note: </b>This is a hyper-optimized version,
          <br />
          <b>60x faster</b> than BatchFinder Premium.
        </p>
        <p
          style={{
            fontSize: '14px',
            color: '#515151',
            textAlign: 'left',
            margin: '0.5rem 0rem',
            padding: '0 1rem',
          }}
        >
          Due to server capacity and limitations, this may not always be
          available.
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: '0.2rem',
        }}
      >
        {!!displayPrice && (
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  color: colors.splashDark,
                  fontWeight: 700,
                }}
              >
                ${displayPrice}
              </p>
            </span>
            <PerDaySavings
              licenseLength={licenseLength}
              savePercent={savePercent}
            />
          </span>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          minWidth: '250px',
          backgroundColor: '#D9D9D9',
          padding: '2px',
          borderRadius: '5px',
          marginTop: '5px',
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: licenseLength === 30 ? '#FBFAF8' : 'transparent',
            fontWeight: licenseLength === 30 ? 600 : 400,
            borderRadius: '4px',
            padding: '4px 0px',
            marginRight: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength(30)}
        >
          30 Days
        </div>
        {licenseLength === 1 && (
          <div
            style={{
              margin: '4px 0px',
              width: '1px',
              backgroundColor: '#9F9F9F',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            backgroundColor: licenseLength === 7 ? '#FBFAF8' : 'transparent',
            fontWeight: licenseLength === 7 ? 600 : 400,
            borderRadius: '4px',
            padding: '4px 0px',
            margin: '0px 1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength(7)}
        >
          7 Days
        </div>
        {licenseLength === 30 && (
          <div
            style={{
              margin: '4px 0px',
              width: '1px',
              backgroundColor: '#9F9F9F',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            backgroundColor: licenseLength === 1 ? '#FBFAF8' : 'transparent',
            fontWeight: licenseLength === 1 ? 600 : 400,
            borderRadius: '4px',
            padding: '4px 0px',
            marginLeft: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength(1)}
        >
          1 Day
        </div>
      </div>
      <Link
        to={`./${handleGetLicenseLink('turbo', licenseLength)}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '10px',
          height: '3rem',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'linear-gradient(135deg, #8b18ff, #2a5fff) padding-box',
          color: 'white',
          fontSize: '20px',
          fontWeight: 900,
          marginTop: '1rem',
          textDecoration: 'none',
          transition: 'transform 0.1s, filter 0.1s',
        }}
        onTouchStart={(e) => {
          e.currentTarget.style.transform = 'scale(0.95)'
        }}
        onTouchEnd={(e) => {
          e.currentTarget.style.transform = 'scale(1)'
        }}
      >
        Purchase Now
      </Link>
    </div>
  )
}
